import axios from 'axios';
import _ from 'lodash';
import moment from 'moment';
import SendIcon from '@mui/icons-material/Send';
import { Alert, Box, Button, Checkbox, Container, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, Radio, RadioGroup, Stack, styled, Switch, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { withFormik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import schema from '../schema/form';
import FormProjects from './FormProjects';
import mapFormValuesToApi from '../transform/form';
import SaveDialog from './SaveDialog';
import RichTextEditor from './RichTextEditor';

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

const StyledContainer = styled(Container)({
  marginTop: '2rem',
});

const StyledFieldset = styled('fieldset')({
  border: `1px solid #ccc`,
  borderRadius: '1rem',
  padding: '2rem',
  marginBottom: '2rem',
  '> legend': {
    background: 'white',
    padding: '0 0.5rem',
    fontFamily: 'neo_sans',
    fontWeight: 'bold',
  }
});

const onSubmit = async (values, actions) => {
  actions.setSubmitting(true);
  await axios
    .post('thx.php', mapFormValuesToApi(values), {
      // options
      headers: { 
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((rsp) => {
      if (rsp.data.success) {
        actions.resetForm(schema.initialValues);
        actions.props.setError();
        actions.props.setSuccess(true);
      } else if (rsp.data.error) {
        actions.props.setError(rsp.data.error);
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }
    })
    .catch((e) => {
      console.log(e);
    })
    .then(() => {
      actions.setSubmitting(false);
    });
};

const Form = ({
  dirty,
  errors,
  handleBlur,
  handleChange,
  handleSubmit,
  isSubmitting,
  isValid,
  setFieldTouched,
  setFieldValue,
  touched,
  values,
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));
  const flexBox = mobile ? null : { display: 'flex' };
  const fieldMargin = mobile ? null : { m: '1rem 2rem 1rem 0 !important' };
  
  React.useEffect(() => {
    if (_.isEmpty(touched) && localStorage.getItem('indexAutoSave')) {
      setOpen(true);
    }
    const interval = window.setInterval(() => {
      if (!_.isEmpty(touched)) {
        localStorage.setItem('indexAutoSave', JSON.stringify(values));
      }
    }, 10000);
    return () => window.clearInterval(interval);
  }, [setFieldValue, touched, values]);

  const restoreForm = () => {
    const savedValues = JSON.parse(localStorage.getItem('indexAutoSave'));
    Object.keys(savedValues).forEach((key) => {
      if (key === 'plannedMissionStart' || key === 'plannedMissionEnd') {
        setFieldValue(key, moment(savedValues[key]).toDate());
      } else {
        setFieldValue(key, savedValues[key]);
      }
      if (!_.isArray(savedValues[key])) {
        setFieldTouched(key, true);
      }
    });
    localStorage.removeItem('indexAutoSave');
    handleClose();
  };

  const handleClose = () => {
    localStorage.removeItem('indexAutoSave');
    setOpen(false);
  };

  return (
    <StyledContainer>
      <h2>{t('form.title')}</h2>
      <Box
        component="form"
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit}
        sx={{
          marginTop: '2rem',
          '& .MuiTextField-root': { m: '1rem 0' },
        }}
      >
        <StyledFieldset>
          <legend>{t('form.applicant')}</legend>
          <TextField
            error={!!(touched.fullname && errors.fullname)}
            fullWidth
            helperText={(touched.fullname && errors.fullname && t(errors.fullname)) || ''}
            label={t('form.fullname')}
            name="fullname"
            onBlur={handleBlur}
            onChange={handleChange}
            required
            value={values.fullname}
          />
          <TextField
            error={!!(touched.email && errors.email)}
            fullWidth
            helperText={(touched.email && errors.email && t(errors.email)) || ''}
            label={t('form.email')}
            name="email"
            onBlur={handleBlur}
            onChange={handleChange}
            required
            value={values.email}
          />
        </StyledFieldset>
        <StyledFieldset>
          <legend>{t('form.mission')}</legend>
          <RichTextEditor
            error={!!(touched.shortDescription && errors.shortDescription)}
            helperText={(touched.shortDescription && errors.shortDescription && t(errors.shortDescription)) || ''}
            label={t('form.shortDescription')}
            name="shortDescription"
            onBlur={handleBlur}
            onChange={handleChange}
            required
            value={values.shortDescription}
          />
          <Box sx={flexBox}>
            <DesktopDatePicker
              disablePast
              label={t('form.plannedMissionStart')}
              inputFormat={t('form.dateFormat')}
              value={values.plannedMissionStart}
              onChange={(value, keyboardInputValue) => {
                const newDate = (keyboardInputValue)
                  ? moment(keyboardInputValue, t('form.dateFormat')).toDate()
                  : value.toDate();
                setFieldTouched('plannedMissionStart', true);
                setFieldValue('plannedMissionStart', newDate);
              }}
              renderInput={(params) => <TextField {...params} sx={fieldMargin} />}
            />
            <DesktopDatePicker
              disablePast
              label={t('form.plannedMissionEnd')}
              inputFormat={t('form.dateFormat')}
              value={values.plannedMissionEnd}
              onChange={(value, keyboardInputValue) => {
                const newDate = (keyboardInputValue)
                  ? moment(keyboardInputValue, t('form.dateFormat')).toDate()
                  : value.toDate();
                setFieldTouched('plannedMissionEnd', true);
                setFieldValue('plannedMissionEnd', newDate);
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          </Box>
          {touched.plannedMissionEnd && errors.plannedMissionEnd ? (<Alert severity="error" sx={{ mb: '1rem' }}>{t(errors.plannedMissionEnd)}</Alert>) : null}
        </StyledFieldset>
        <StyledFieldset>
          <legend>{t('form.projects')}</legend>
          <FormProjects
            errors={errors}
            handleBlur={handleBlur}
            handleChange={handleChange}
            setFieldTouched={setFieldTouched}
            setFieldValue={setFieldValue}
            touched={touched}
            values={values}
          />
        </StyledFieldset>
        <StyledFieldset>
          <RichTextEditor
            error={!!(touched.missionDescription && errors.missionDescription)}
            helperText={(touched.missionDescription && errors.missionDescription && t(errors.missionDescription)) || ''}
            label={t('form.missionDescription')}
            name="missionDescription"
            onBlur={handleBlur}
            onChange={handleChange}
            required
            value={values.missionDescription}
          />
          <RichTextEditor
            error={!!(touched.diveSiteLocation && errors.diveSiteLocation)}
            helperText={(touched.diveSiteLocation && errors.diveSiteLocation && t(errors.diveSiteLocation)) || t('form.diveSiteLocationHelp')}
            label={t('form.diveSiteLocation')}
            name="diveSiteLocation"
            onBlur={handleBlur}
            onChange={handleChange}
            required
            value={values.diveSiteLocation}
          />
          <Stack direction="row" alignItems="center">
            <Typography marginRight={2}>
              {t('form.numberOfPlanedDives')} *
            </Typography>
            <TextField
              error={!!(touched.numberOfPlanedDives && errors.numberOfPlanedDives)}
              helperText={(touched.numberOfPlanedDives && errors.numberOfPlanedDives && t(errors.numberOfPlanedDives)) || ''}
              name="numberOfPlanedDives"
              onBlur={handleBlur}
              onChange={handleChange}
              required
              value={values.numberOfPlanedDives}
              variant="standard"
            />
          </Stack>
          <Stack direction="row" alignItems="center">
            <Typography marginRight={2}>
              {t('form.maximumDiveDepth')}
            </Typography>
            <TextField
              name="maximumDiveDepth"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.maximumDiveDepth}
              variant="standard"
            />
          </Stack>
          <Stack direction="row" alignItems="center">
            <Typography marginRight={2}>
              {t('form.maximumDiveTime')}
            </Typography>
            <TextField
              name="maximumDiveTime"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.maximumDiveTime}
              variant="standard"
            />
          </Stack>
          <Box sx={{ m: '1rem 0' }}>
            <FormControl>
              <FormLabel id="plannedDecompressionDivesLabel">{t('form.plannedDecompressionDives')}</FormLabel>
              <Stack direction="row" spacing={1} alignItems="center">
                <Typography>{t('general.no')}</Typography>
                <Switch
                  name="plannedDecompressionDives"
                  checked={values.plannedDecompressionDives}
                  onChange={handleChange}
                  inputProps={{ 'aria-label': 'decompression dives' }}
                />
                <Typography>{t('general.yes')}</Typography>
              </Stack>
            </FormControl>
          </Box>
          <Box sx={{ m: '1rem 0' }}>
            <FormControl>
              <FormLabel id="breathingGasLabel">{t('form.breathingGas.label')}</FormLabel>
              <RadioGroup
                row
                aria-labelledby="breathingGasLabel"
                name="breathingGas"
                value={values.breathingGas}
                onChange={handleChange}
              >
                <FormControlLabel value="air" control={<Radio />} label={t('form.breathingGas.air')} />
                <FormControlLabel value="nitrox" control={<Radio />} label={t('form.breathingGas.nitrox')} />
                <FormControlLabel value="mixed" control={<Radio />} label={t('form.breathingGas.mixed')} />
              </RadioGroup>
            </FormControl>
          </Box>
          <RichTextEditor
            error={!!(touched.plannedUnderwaterWork && errors.plannedUnderwaterWork)}
            helperText={(touched.plannedUnderwaterWork && errors.plannedUnderwaterWork && t(errors.plannedUnderwaterWork)) || t('form.plannedUnderwaterWorkHelp')}
            label={t('form.plannedUnderwaterWork')}
            name="plannedUnderwaterWork"
            onBlur={handleBlur}
            onChange={handleChange}
            required
            value={values.plannedUnderwaterWork}
          />
        </StyledFieldset>
        <StyledFieldset>
          <Grid container alignItems="center">
            <Grid item xs={4}>
              {t('form.diveMissionLeader')}
            </Grid>
            <Grid item xs={8}>
              <TextField
                error={!!(touched.diveMissionLeader && errors.diveMissionLeader)}
                fullWidth
                helperText={(touched.diveMissionLeader && errors.diveMissionLeader && t(errors.diveMissionLeader)) || ''}
                name="diveMissionLeader"
                onBlur={handleBlur}
                onChange={handleChange}
                required
                value={values.diveMissionLeader}
              />
            </Grid>
          </Grid>
          {values.divers.length > 0 && values.divers.map((diver, i) => (
            <Grid key={`diver${i}`} container alignItems="center">
              <Grid item xs={4}>
                {`${i + 1}. ${t('form.diver')}`}
              </Grid>
              <Grid item xs={8}>
                <TextField
                  fullWidth
                  name={`divers[${i}].fullname`}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={diver.fullname}
                />
              </Grid>
            </Grid>
          ))}
          <RichTextEditor
            label={t('form.furtherComments')}
            name="furtherComments"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.furtherComments}
          />
        </StyledFieldset>
        <StyledFieldset>
          <FormControl
            error={!!(touched.consent && errors.consent)}
            required
          >
            <FormLabel>
              {t('form.consent')}
            </FormLabel>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={values.consent}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    name="consent"
                  />
                }
                label={t('form.consentMsg')}
              />
            </FormGroup>
            {touched.consent && errors.consent ? (<Alert severity="error" sx={{ mb: '1rem' }}>{t(errors.consent)}</Alert>) : null}
          </FormControl>
        </StyledFieldset>
        <Button
          disabled={!dirty || !isValid || isSubmitting}
          endIcon={<SendIcon />}
          sx={{ m: '2rem 0'}}
          type="submit"
          variant="contained"
        >
          {t('form.submit')}
        </Button>
      </Box>
      <SaveDialog
        handleAccept={restoreForm}
        handleClose={handleClose}
        open={open}
      />
    </StyledContainer>
  );
};

Form.propTypes = {
  errors: PropTypes.shape({
    consent: PropTypes.string,
    diveMissionLeader: PropTypes.string,
    diveSiteLocation: PropTypes.string,
    email: PropTypes.string,
    fullname: PropTypes.string,
    missionDescription: PropTypes.string,
    numberOfPlanedDives: PropTypes.string,
    plannedMissionEnd: PropTypes.string,
    plannedUnderwaterWork: PropTypes.string,
    projects: PropTypes.arrayOf(PropTypes.shape({})),
    shortDescription: PropTypes.string,
  }),
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  dirty: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  isValid: PropTypes.bool,
  setFieldTouched: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  touched: PropTypes.shape({
    consent: PropTypes.bool,
    diveMissionLeader: PropTypes.bool,
    diveSiteLocation: PropTypes.bool,
    email: PropTypes.bool,
    fullname: PropTypes.bool,
    missionDescription: PropTypes.bool,
    numberOfPlanedDives: PropTypes.bool,
    plannedMissionEnd: PropTypes.bool,
    plannedUnderwaterWork: PropTypes.bool,
    projects: PropTypes.arrayOf(PropTypes.shape({})),
    shortDescription: PropTypes.bool,
  }),
  values: PropTypes.shape({
    breathingGas: PropTypes.string,
    consent: PropTypes.bool,
    diveMissionLeader: PropTypes.string,
    divers: PropTypes.arrayOf(PropTypes.shape({})),
    diveSiteLocation: PropTypes.string,
    email: PropTypes.string,
    furtherComments: PropTypes.string,
    fullname: PropTypes.string,
    maximumDiveDepth: PropTypes.string,
    maximumDiveTime: PropTypes.string,
    missionDescription: PropTypes.string,
    numberOfPlanedDives: PropTypes.string,
    plannedDecompressionDives: PropTypes.bool,
    plannedMissionEnd: PropTypes.instanceOf(Date),
    plannedMissionStart: PropTypes.instanceOf(Date),
    plannedUnderwaterWork: PropTypes.string,
    projects: PropTypes.arrayOf(PropTypes.shape({})),
    shortDescription: PropTypes.string,
  }),
};

Form.defaultProps = {
  errors: {},
  dirty: false,
  isSubmitting: false,
  isValid: false,
  touched: {},
  values: {},
};

export default withFormik({
  displayName: 'Form',
  handleSubmit: (values, actions) => onSubmit(values, actions),
  mapPropsToValues: () => schema.initialValues,
  validateOnMount: false,
  validationSchema: schema.validationSchema,
})(Form);
