import moment from 'moment';

const mapBreathingGasToApi = (breathingGas) => {
  switch (breathingGas) {
    case 'nitrox':
      return 'Nitrox / Nitrox';
    case 'mixed':
      return 'Mischgas / Mixed gas';
    case 'air':
    default:
      return 'Pressluft / Air';
  }
};

const mapDecompressionToApi = (decompression) => {
  switch (decompression) {
    case 'yes':
      return 'Ja / Yes';
    case 'no':
    default:
      return 'Nein / No';
  }
};

const mapFormValuesToApi = (values) => {
  const apiValues = {
    antrag_antragsteller: values.fullname,
    antrag_antragsteller_email: values.email,
    antrag_geplant_beginn: moment(values.plannedMissionStart).format('DD.MM.YYYY'),
    antrag_geplant_ende: moment(values.plannedMissionEnd).format('DD.MM.YYYY'),
    antrag_kurzbezeichnung: values.shortDescription,
    antrag_projekte: values.projects.map((project) => ({
      projekt_kurzname: project.shortName,
      projekt_antragsteller: project.applicant,
      projekt_mailadresse: project.mailAddress,
      projekt_kostenstelle: project.costCentre,
      projekt_rechnungsadresse: project.invoiceAddress,
      projekt_kommentar: project.comment,
    })),
    antrag_projektbeschreibung: values.missionDescription,
    antrag_tauchgebiet: values.diveSiteLocation,
    antrag_anzahl_tauchgaenge: values.numberOfPlanedDives,
    antrag_max_einsatztiefe: values.maximumDiveDepth,
    antrag_max_einsatzdauer: values.maximumDiveTime,
    antrag_dekompressionstauchgaenge: mapDecompressionToApi(values.plannedDecompressionDives),
    antrag_atemgas: mapBreathingGasToApi(values.breathingGas),
    antrag_aufgaben: values.plannedUnderwaterWork,
    antrag_einsatzleiter: values.diveMissionLeader,
    antrag_bemerkungen: values.furtherComments,
    antrag_datenzustimmung: values.consent ? 1 : 0,
    antrag_speichern: true,
  };
  for (let i = 0; i < values.divers.length; i++) {
    apiValues[`antrag_einsatztaucher${i + 1}`] = values.divers[i].fullname;
  }
  return apiValues;
}

export default mapFormValuesToApi;
