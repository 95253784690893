import yup from './yup';

const initialValues = {
  fullname: '',
  email: '',
  shortDescription: '',
  plannedMissionStart: new Date(),
  plannedMissionEnd: new Date(),
  projects: Array(1).fill({
    shortName: '',
    applicant: '',
    mailAddress: '',
    costCentre: '',
    invoiceAddress: '',
    comment: '',
  }),
  missionDescription: '',
  diveSiteLocation: '',
  numberOfPlanedDives: '',
  maximumDiveDepth: '',
  maximumDiveTime: '',
  plannedDecompressionDives: false,
  breathingGas: '',
  plannedUnderwaterWork: '',
  diveMissionLeader: '',
  divers: Array(10).fill({
    fullname: '',
  }),
  consent: false,
};

const validationSchema = yup.object().shape({
  fullname: yup.string().required('form.error.fullname.required'),
  email: yup.string().required('form.error.email.required').email(),
  shortDescription: yup.string().required('form.error.shortDescription.required'),
  plannedMissionStart: yup.date()
    .required('form.error.plannedMissionStart.required'),
  plannedMissionEnd: yup.date()
    .required('form.error.plannedMissionEnd.required')
    .min(yup.ref('plannedMissionStart'), 'form.error.plannedMissionEnd.min'),
  projects: yup.array().of(yup.object().shape({
    shortName: yup.string().required('form.error.project.shortName.required'),
    applicant: yup.string().required('form.error.project.applicant.required'),
    mailAddress: yup.string().required('form.error.project.mailAddress.required').email(),
    costCentre: yup.string().required('form.error.project.costCentre.required'),
    invoiceAddress: yup.string().required('form.error.project.invoiceAddress.required'),
    comment: yup.string(),
  })),
  missionDescription: yup.string().required('form.error.missionDescription.required'),
  diveSiteLocation: yup.string().required('form.error.diveSiteLocation.required'),
  numberOfPlanedDives: yup.string().required('form.error.numberOfPlanedDives.required'),
  maximumDiveDepth: yup.string(),
  maximumDiveTime: yup.string(),
  breathingGas: yup.string().oneOf(['air', 'nitrox', 'mixed'], 'form.error.breathingGas.required'),
  plannedUnderwaterWork: yup.string().required('form.error.plannedUnderwaterWork.required'),
  diveMissionLeader: yup.string().required('form.error.diveMissionLeader.required'),
  divers: yup.array().of(yup.object().shape({
    fullname: yup.string(),
  })),
  consent: yup.bool().oneOf([true], 'form.error.consent.required'),
});

const schema = {
  initialValues,
  validationSchema,
};

export default schema;
